@import "../styles/variables.scss";

.header {
  background-image: linear-gradient(to bottom, #151ea2, #0e1ac4 73%);
  background-color: #151ea2;
}

.card {
  color: $white;
  display: flex;
  background-image: url("../images/img-header.png");
  background-repeat: no-repeat;
  background-position: top center;
  height: 1080px;
  max-width: 1280px;
  margin: 0 auto;
  height: 1080px;
  min-height: 600px;
  a {
    color: #fff;
    transition: .2s;
  }
  a:hover {
    color: #B1CFFF;
  }
  @media (max-width: $break-point-lg) {
    max-width: 1060px;
    background-position: top left 440px;
  }
  @media (max-width: $break-point-md) {
    padding: 0 100px;
    max-width: none;
    background-position: top left 550px;
  }
  @media (max-width: $break-point-sm) {
    padding: 0px 30px;
    background-repeat: no-repeat;
    background-position: top left 400px;
    background-size: auto 620px;
    height: 720px;
  }
  @media (max-width: $break-point-xs) {
    background-image: url("../images/img-header-mobile-uncropped.png");
    background-position: top -68px left 260px;
    background-size: 160px 671px;
  }
}

.inner {
  display: flex;
  width: 100%;
}

.img {
  justify-self: center;
}

.text {
  align-self: center;
  @media (max-width: $break-point-sm) {
    align-self: flex-start;
    padding-top: 120px;
  }
}

.title {
  font-size: 78px;
  font-weight: bold;
  margin-bottom: 46px;
  letter-spacing: -0.7px;
  line-height: 1.03;

  @media (max-width: $break-point-sm) {
    font-size: 52px;
    margin-bottom: 33px;
    letter-spacing: -0.5px;
    line-height: 1.04;
  }
}

.info {
  font-size: 18px;
  font-style: italic;
  opacity: 0.7;
  line-height: 1.56;
  letter-spacing: -0.27px;

  .mobile_br {
    display: none;
  }

  @media (max-width: $break-point-sm) {
    font-size: 15px;
    line-height: 1.47;
    max-width: 100%;
  }
  @media (max-width: $break-point-xs) {
    .mobile_br {
      display: inline;
    }
  }
}

.learn {
  margin-top: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  span {
    margin-right: 8px;
    margin-top: -2px;
    font-size: 14px;
    font-weight: 500;
  }
  button {
    pointer-events: none;
  }
}

.button {
  background: none;
  border: none;
  pointer-events: none;

  img {
    width: 26px;
  }
}
