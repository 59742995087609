$title-color: #0b159d;
$text-color: #282828;
$break-point-lg: 1399px;
$break-point-md: 1179px;
$break-point-sm: 767px;
$break-point-xs: 639px;
$white: #ffffff;
$skyblue: #5a99f7;
$blue: #0e1ac4;
$gray: #a5a5a5;
$partnerCard-color: #f5f5f5;
$subtext-color: #cacaca;
$applyButton-color: #3e99ff;
